<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการเงื่อนไขขนส่งภายในประเทศ
          </h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-button variant="main" @click="addNewConditon()"
              >เพิ่มเงื่อนไข</b-button
            >
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(action)="data">
                <div class="text-center">
                  <router-link
                    :to="'/shipping-setting/thai/details/' + data.item.id"
                  >
                    <b-button variant="link" class="text-dark px-1 py-0">
                      แก้ไข
                    </b-button>
                  </router-link>
                  <b-button
                    variant="link"
                    @click="deleteData(data.item.id)"
                    class="text-dark px-1 py-0"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "Shipping",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    InputText,
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      id: "",
      requestDeleteBanner: {
        id: null,
      },
      fields: [
        {
          key: "shippingTypeName",
          label: "ชื่อขนส่ง",
          class: "w-100px",
        },

        {
          key: "shippingConditionName",
          label: "ประเภทเงื่อนไข",
          class: "w-100px",
        },
        {
          key: "priceRange",
          label: "ช่วงราคา",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [{ start_weight: "", weight: "", price: "", id: 0 }],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Type: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      isDisable: false,
      deleteItems: {},
      addItems: [],
    };
  },
  validations: {
    items: {
      $each: {
        value: { required, minValue: minValue(1) },
        price: { required, minValue: minValue(1) },
      },
    },
  },
  created: async function () {
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function (filter = this.filter) {
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Shipping/ThaiShipping`,
        null,
        this.$headers,
        filter
      );

      if (resData.result == 1) {
        if (resData.detail.length != this.filter.PerPage) {
          this.items = [...resData.detail, ...this.addItems];
        } else {
          this.items = resData.detail.dataList;
        }

        this.rows = resData.detail.count + this.addItems.length;
        this.isBusy = false;
        this.$isLoading = true;
      }
    },

    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    async deleteConditon(items) {
      this.$refs.ModalAlertConfirm.show();
      this.modalMessage = "";
      this.deleteItems = items;
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Delete`,
        null,
        this.$headers,
        this.deleteItems
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        // this.filter.PageNo = 1;
        this.rows -= 1;
        if (
          this.filter.PageNo >
          Math.ceil((this.rows + this.addItems.length) / this.filter.PerPage)
        )
          this.filter.PageNo -= 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    addNewConditon() {
      this.$router.push({
        path: `/shipping-setting/thai/details/0`,
      });
    },
    async updateCondition() {
      let payload = this.items.filter((el) => el.id != 0);
      if (payload.length == 0) return await this.getList();
      this.isBusy = true;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Update`,
        null,
        this.$headers,
        payload
      );

      if (resData.result == 1) {
        await this.getList();

        this.isBusy = false;
        this.$isLoading = true;
      } else {
        this.isBusy = false;
        this.$isLoading = true;
        this.modalMessage = resData.detail.join(",");
        this.$refs.modalAlertError.show();
      }
    },
    async addCondition() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Insert`,
        null,
        this.$headers,
        this.addItems
      );

      if (resData.result == 1) {
        this.addItems = [];
      } else {
        this.isBusy = false;
        this.$isLoading = true;
        this.modalMessage = resData.detail.join(",");
        this.$refs.modalAlertError.show();
      }
    },
    async submit() {
      this.$v.items.$touch();
      if (this.$v.items.$error) return;
      if (this.addItems.length > 0) await this.addCondition();
      this.updateCondition();
    },
  },
};
</script>

<style scoped>
.image {
  width: 75px;
  padding-top: 75px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}
</style>
